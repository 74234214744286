<template>
  <div class="playground-generals-inf-container container-fluid">
    <b-card class="playground-generals-inf-card">
      <div class="row-style">
        <d-body-nav-bar
          :tabs="[]"
          title="views.playground.steps.step-1.informations.activities.list"
          title-class="topnav-title-red-light"
          position="float-right"
        />
      </div>
      <d-table
        identifier="blockPrice"
        :tablefields="tableFields"
        :items="items"
        :per-page="itemsPerPage"
        :totalRows="totalRows"
        :is-busy="isBusy"
        :current-page="currentPage"
        @on:pagination-model:update="onPaginationModelChange"
        @on:blockPrice:delete="onRowDelete"
        @on:data-table-btn:edit="onRowEdit"
        @on:plus-icon-click="onPlusClick"
        @current-page:update="onCurrentPageUpdate"
      >
      </d-table>
      <b-row no-gutters class="mt-3" align="center">
        <b-col>
          <d-button
            :text="'views.playground.steps.step-1.informations.activities.table.add-new-niche-activity'"
            :class="selectedActivities ? '' : 'disabled'"
            icon="fa fa-plus mr-1"
            class="d-btn-sm btn d-btn-primary-outline font-text-title"
            @on:button-click="displayBlockPriceModal"
          />
        </b-col>
      </b-row>
    </b-card>
    <block-price-modal
      v-if="playgroundActivities.length > 0"
      :key="'modal-key' + blockPriceModalKey"
      :display="display"
      :playground="playground"
      :block-price="blockPrice"
      :playground-activities="playgroundActivities"
      @hidden="display = false; blockPriceModalKey ++"
      @reload:block-prices="onBlockPriceSaved"
    />
  </div>
</template>
<script>

import {
  deletePrice,
  getExculdedPrices,
  getPlaygroundPrices, getPrice,
} from "@api/doinsport/services/timetable/blocks/price/price.api";
import Price, {
  FORMULA_TYPE,
  LEISURE_TYPE,
  LESSON_TYPE,
  SPORT_TYPE,
  SUBSCRIBERS_REGISTRATION
} from "@/classes/doinsport/Price";
import {getDuration} from "@/utils/date";
import BlockPriceModal from "@custom/playground/new/step-1/BlockPriceModal";
import {getPlaygroundActivities} from "@api/doinsport/services/playground/playground.api";
import {refine} from "@/utils/classes";
import {fromIriReferenceToId} from "@/utils/form";
import {URI_SUBSCRIPTION_PLAN} from "@api/doinsport/services/subscription-plans/subscription-plans.api";
import {SUCCESS} from "@plugins/flash";

export default {
  props: {
    playground: {
      type: Object,
      default: this,
    },
    nicheActivitiesSelection: {
      type: Array,
      default: this,
    },
    isPlaygroundPrices: {
      type: Boolean,
      default: false,
    },
    reloaded: {
      type: Boolean,
      default: false,
    },
    playgroundTimetables: {
      type: Array,
      default: this,
    },
    pricesListSelectionStep3: {
      type: Array,
      default: this,
    },

  },
  components: {
    BlockPriceModal,
  },
  data: () => ({
    nicheModalActivityKey: 1,
    blockPriceModalKey: 1,
    items: [],
    blockPrice: new Price(),
    playgroundActivities: [],
    itemsPerPage: 10,
    totalRows: 1,
    currentPage: 1,
    pagination: 1,
    display: false,
    isBusy: false,
    deeped: false,
    mode: 'create',
  }),
  watch: {
    reloaded: function () {
      this.loadPrices(this.itemsPerPage, this.pagination);
    }
  },
  computed: {
    selectedActivities() {
      return this.playground.activities.length > 0;
    },
    alignCellsToLeft() {
      return this.$store.getters['layout/getInnerWidth'] >= 991 ? 'text-center' : 'text-left';
    },
    selectedBlockPrices() {
      return this.$store.getters["playgrounds/getSelectedBlockPrices"];
    },
    tableFields() {
      return [
        {
          key: "plusIcon",
          class: 'selection-checkbox-td text-center',
          label: this.$t('components.doinsport.table.selection'),
          sortable: false
        },
        {
          key: "name",
          label: this.$t("views.playground.steps.step-1.informations.activities.table.wording"),
          sortable: false
        },
        {
          key: "activity",
          label: this.$t("views.playground.steps.step-1.informations.activities.table.activity"),
          sortable: false
        },
        {
          key: "activityType",
          label: this.$t("components.form.prices.type.label"),
          class: 'text-center',
          sortable: false
        },
        {
          key: "maxParticipantsCountLimit",
          class: 'text-center',
          label: this.$t("views.playground.steps.step-1.informations.activities.table.nb-players"),
          sortable: false
        },
        {
          key: "nicheDuration",
          label: this.$t("views.playground.steps.step-1.informations.activities.table.duration"),
          sortable: false
        },
        {
          key: "pricePerParticipant",
          class: this.alignCellsToLeft,
          label: this.$t("views.playground.steps.step-1.informations.activities.table.public-price"),
          sortable: false
        },
        {key: "actions", label: this.$t('general.actions.actions')},
      ];
    }
  },
  methods: {
    onPlusClick(row) {
      row.plusIcon = false;
      this.$store.dispatch('playgrounds/addBlockPrice', row);
    },
    onBlockPriceSaved() {
      this.blockPriceModalKey++;
      this.display = false;
      this.$store.dispatch('blockPrice/saveBlockPriceVariations', []);

      this.loadPrices(this.itemsPerPage, this.pagination);
    },
    displayBlockPriceModal() {
      this.$store.dispatch('blockPrice/saveBlockPriceVariations', []);

      if (this.selectedActivities) {
        this.blockPrice = new Price();

        this.display = true;
      }
    },
    onRowDelete(price) {
      deletePrice(price.id)
        .then((response) => {
          this.$flash(null, this.$t('views.playground.steps.step-1.informations.activities.success-delete'), 5000, SUCCESS);
        })
        .finally(() => {
          this.$store.dispatch('playgrounds/removeBlockPrice', price);
          this.loadPrices(this.itemsPerPage, this.pagination);
        })
        .catch(() => {
          this.$flash(this.$t('general.actions.error'), this.$t('general.actions.error-notification'));
        })
      ;
    },
    onRowEdit(price) {
      this.$store.dispatch('blockPrice/saveBlockPriceVariations', []);

      getPrice(price.id)
        .then((response) => {
          this.blockPrice = new Price(response.data, {deserialize: true});
          refine(this.blockPrice);
          this.blockPrice.paymentTokenPrices = response.data.paymentTokenPrices;
          this.blockPrice.allowedSubscriptionPlans = this.getSubscriptionPlansSelection(response.data);
          this.blockPrice.variations = response.data.variations;
          this.display = true;
        })
      ;
    },
    getSubscriptionPlansSelection(blockPrice) {
      const subscriptionsPlansSelection = [];

      if (blockPrice.activityType === LESSON_TYPE) {
        if (blockPrice.registrationAvailableFor === SUBSCRIBERS_REGISTRATION) {
          for (const subscriptionPlan of blockPrice.allowedSubscriptionPlans) {
            subscriptionsPlansSelection.push({
              code: fromIriReferenceToId(URI_SUBSCRIPTION_PLAN + '/', subscriptionPlan['@id']),
              label: subscriptionPlan.name,
            });
          }
        }
      }

      return subscriptionsPlansSelection;
    },
    onPaginationModelChange(pagination) {
      this.pagination = pagination;
    },
    onCurrentPageUpdate(page) {
      this.loadPrices(this.itemsPerPage, page);
    },
    getPricesRequest(itemsPerPage, page) {
      const filters = this.playground.bookingType === 'multiple' ? [LESSON_TYPE, LEISURE_TYPE, FORMULA_TYPE] : [LESSON_TYPE, SPORT_TYPE, FORMULA_TYPE];

      if (this.isPlaygroundPrices) {
        return getPlaygroundPrices(itemsPerPage, page, this.$route.params.id, filters);
      } else {
        return getExculdedPrices(itemsPerPage, page, this.playground.activities, [this.$route.params.id], filters);
      }
    },
    loadPrices(itemsPerPage, page) {
      this.isBusy = true;

      if (this.playground.activities.length > 0) {
        this.getPricesRequest(itemsPerPage, page)
          .then((response) => {
            this.totalRows = response.data['hydra:totalItems'];
            this.items = [];

            for (const price of response.data['hydra:member']) {
              const priceClass = new Price(price, {serialize: false, deserialize: true});
              priceClass.activityType = this.$t(`components.form.prices.type.${priceClass.activityType}`);
              const objActions = {actions: ['edit', 'delete']};


              const findBlockPrice = this.selectedBlockPrices.find(el => el.id === priceClass.id)
              if ("undefined" !== typeof findBlockPrice) {
                Object.assign(priceClass, {plusIcon: false});
              } else {
                Object.assign(priceClass, {plusIcon: true});
              }
              Object.assign(priceClass, objActions);
              Object.assign(priceClass, {nicheDuration: getDuration(priceClass.duration)});
              Object.assign(priceClass, {otherPrices: this.$t('general.actions.details')});

              this.items.push(priceClass);
            }
          })
          .finally(() => {
            this.isBusy = false
          })
        ;
      } else {
        this.items = [];
        this.isBusy = false;
      }
    },
    loadPlaygroundActivities() {
      getPlaygroundActivities(this.$route.params.id)
        .then((response) => {
          this.playgroundActivities = response.data['hydra:member'];
        })
      ;
    }
  },
  created() {
    this.loadPlaygroundActivities();
    this.mode = this.$router.currentRoute.name === 'playground_new' ? 'create' : 'update';
  },
  beforeDestroy() {
    try {
      this.$bus.$off('refresh-activities');
      this.$bus.$off('reset-priceClass');
    } catch (e) {
    }
  },
  mounted() {
    this.$bus.$on('refresh-activities', () => {
      this.isBusy = true;

      this.loadPrices(this.itemsPerPage, this.pagination);
    });

    this.loadPrices(this.itemsPerPage, this.currentPage);
  }
}
</script>
<style scoped>
.playground-generals-inf-card .card-body {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 250px;
  padding: 1.25rem;
}

.playgraound-general-title {
  text-align: left;
  font: normal normal 900 22px Avenir;
  letter-spacing: 0px;
  color: #FF0101;
  opacity: 1;
}

.playground-generals-inf-container .card {
  box-shadow: 0px 10px 16px #E2E1E6;
  border-radius: 8px;
}

/deep/ .datatable-vue table tbody tr td:last-child {
  padding-left: 0;
  padding-right: 0;
}
</style>
