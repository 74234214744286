import {_delete, _get, _post, _put, clubId} from "@api/doinsport/services/httpService";

export const BLOCK_PRICE_CATEGORY_URI = '/clubs/playgrounds/timetables/blocks/prices/categories';

export const getBlockPriceCategories = (activityType) => _get(BLOCK_PRICE_CATEGORY_URI + '?activityType=' + activityType + '&club.id=' + clubId);

export const getBlockPriceCategory = (id) => _get(BLOCK_PRICE_CATEGORY_URI + '/' + id);

export const getAllBlockPriceCategories = () => _get(BLOCK_PRICE_CATEGORY_URI + '?club.id=' + clubId + '&page=1&itemsPerPage=120');

export const postBlockPriceCategory = (blockPriceCategory) => _post(BLOCK_PRICE_CATEGORY_URI, blockPriceCategory);

export const putBlockPriceCategory = (blockPriceCategory) => _put(BLOCK_PRICE_CATEGORY_URI + '/' + blockPriceCategory.id, blockPriceCategory);

export const deleteBlockPriceCategory = (id) => _delete(BLOCK_PRICE_CATEGORY_URI + '/' + id);
