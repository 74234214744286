import { hydrate, randomString } from "@/utils/form";

export default class PaymentTokenPrice {
    id = randomString();
    publicPrice = 0;
    paymentToken = null;
    blockPrice = null;
    blockPriceVariations = [];

    constructor(object, options) {
        if ("undefined" !== typeof options) {
            if (options.deserialize) {
                this.deserialize(object);
            }
            if (options.serialize) {
                this.serialize(object);
            }
            if (options.serialize_variations) {
                this.serializeVariations(object)
            }
        }
    }

    deserialize(object) {
        hydrate(this, object);
    }

    serialize(object) {
        hydrate(this, object);
    }
    serializeVariations(variations) {
        for (const variation of variations) {
            const creditVariation = {
                recent: true,
                subscriptionPlan: variation.subscriptionPlan,
                pricePerParticipant: 0,
            }
            this.blockPriceVariations.push(creditVariation);
        }
    }
}
