<template>
  <div>
    <validation-observer
      slim
      :ref="observerRef"
    >
      <b-row>
        <b-col>
          <label class="title-step">
            {{
              blockPrice.activityType === 'leisure' ?
                $t('components.form.playground.block-price.step-1.public-rates.title') :
                $t('components.form.playground.block-price.step-1.public-rates.single-rate-title')
            }}
          </label>
        </b-col>
      </b-row>
      <b-row v-if="blockPrice.activityType === 'formula'">
        <b-col cols="12">
          <label class="field-name-class">
            {{ $t('components.form.playground.block-price.step-1.public-rates.form.label') }}
          </label>
          <div class="d-flex flex-row align-content-center align-items-center">
            <select
              v-model="blockPrice.form"
              class="form-control background-light-blue-inputs custom-select-icon-option"
            >
              <option
                :value="null"
                disabled
              >
                {{ $t('general.actions.choose') }}
              </option>
              <template v-for="(form, iterator) of forms">
                <option
                  :key="'categoryForm' + iterator"
                  :value="form['@id']"
                >
                  {{ form.name }}
                </option>
              </template>
            </select>
          </div>
          <b-form-invalid-feedback
            :state="blockPrice.form !== null"
          >
            {{ $t('validation.required') }}
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
      <div v-if="blockPrice.activityType === 'leisure'">
        <template
          v-for="(blockPriceCategory, iterator) of blockPriceCategories"
        >
          <b-row
            v-if="notADeletedCategory(blockPriceCategory)"
            class="mt-2"
          >
            <b-col
              align="center"
              class="d-flex justify-content-center align-items-center"
            >
            <span
              v-if="iterator > 0"
              class="pointer align-items-center align-content-center fa fa-minus text-danger-light mt-4"
              @click="removeBlockPriceCategory(blockPriceCategory)"
            />
            </b-col>
            <b-col cols="4">
              <label class="field-name-class">
                {{ $t('components.form.playground.block-price.step-1.public-rates.category.label') }}
              </label>
              <div class="d-flex flex-row align-content-center align-items-center">
                <select
                  v-model="blockPriceCategory.category"
                  class="custom-right-borders-select form-control background-light-blue-inputs"
                >
                  <option
                    disabled
                    :value="null"
                  >
                    {{ $t('general.actions.choose') }}
                  </option>
                  <template v-for="(category, iterator) of categories">
                    <option
                      :key="'category' + iterator"
                      :value="category['@id']"
                      :disabled="isActiveCategoryOption(category)"
                    >
                      {{ category.name }}
                    </option>
                  </template>
                </select>
                <d-button
                  class="d-btn-primary d-btn-sm-icon custom-left-borders btn-custom-size"
                  icon="fa fa-plus-circle"
                  @on:button-click="displayModalCategory = !displayModalCategory"
                />
              </div>
              <b-form-invalid-feedback
                :state="blockPriceCategory.category !== null"
              >
                {{ $t('validation.required') }}
              </b-form-invalid-feedback>
            </b-col>
            <b-col cols="4">
              <label class="field-name-class">
                {{ $t('components.form.playground.block-price.step-1.public-rates.form.label') }}
              </label>
              <div class="d-flex flex-row align-content-center align-items-center">
                <select
                  v-model="blockPriceCategory.form"
                  class="form-control background-light-blue-inputs custom-select-icon-option"
                >
                  <option disabled :value="null">
                    {{ $t('general.actions.choose') }}
                  </option>
                  <template v-for="(form, iterator) of forms">
                    <option
                      :key="'categoryForm' + iterator"
                      :value="form['@id']"
                    >
                      {{ form.name }}
                    </option>
                  </template>
                </select>
              </div>
              <b-form-invalid-feedback
                :state="blockPriceCategory.form !== null"
              >
                {{ $t('validation.required') }}
              </b-form-invalid-feedback>
            </b-col>
            <b-col cols="3">
              <label class="field-name-class">
                {{ $t('components.form.playground.block-price.step-1.public-rates.price.label') }}
              </label>
              <div class="d-flex flex-row align-content-center align-items-center">
                <b-input-group>
                  <b-form-input
                    v-model="blockPriceCategory.pricePerParticipant"
                    type="number"
                    min="0"
                    size="sm"
                    autocomplete="off"
                    class="background-light-blue-inputs"
                    :placeholder="$t('general.actions.numbers.zero')"
                  >
                  </b-form-input>
                  <b-input-group-append is-text>
                    <div>{{ $currency }}</div>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <b-form-invalid-feedback
                :state="parseFloat(blockPriceCategory.pricePerParticipant) >= 0"
              >
                {{ $t('validation.required') }}
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
        </template>
        <div class="border-bottom-grey mt-3 mb-3"/>
        <AddRate
          text-link="components.form.subscriptions.new.add-new-rate"
          :pointer="blockPriceCategories.length < categories.length ? 'pointer' : ''"
          :class-icon="blockPriceCategories.length < categories.length ? 'fa fa-plus text-blue-darken' : 'fa fa-plus text-blue-disabled'"
          @on:add-link-click="addNewRate"
        />
      </div>
      <div class="mt-3" v-else>
        <b-row>
          <b-col cols="8">
            {{ $t('components.form.playground.block-price.step-1.public-rates.public-price') }}
          </b-col>
          <b-col cols="4">
            <div class="d-flex flex-row align-content-center align-items-center">
              <b-input-group>
                <b-form-input
                  v-model="blockPrice.pricePerParticipant"
                  :placeholder="$t('general.actions.numbers.zero')"
                  min="0"
                  size="sm"
                  type="number"
                  autocomplete="off"
                  class="background-light-blue-inputs"
                >
                </b-form-input>
                <b-input-group-append is-text>
                  <div>{{ $currency }}</div>
                </b-input-group-append>
              </b-input-group>
            </div>
            <b-form-invalid-feedback
              :state="parseFloat(blockPrice.pricePerParticipant) >= 0"
            >
              {{ $t('validation.required') }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
      </div>
      <template v-if="blockPrice.activityType !== 'formula'">
        <b-row :class="marginSection">
          <b-col>
            <div class="title-step">
              {{ $t('components.form.playground.block-price.step-1.subscription-rates.title') }}
            </div>
          </b-col>
        </b-row>
        <div v-if="!isLoadingSubscriptionPlans">
          <div
            class="container-scroll"
          >
            <template v-for="(variation, iterator) of blockPriceVariations">
              <b-row
                class="mt-2"
                :key="iterator + '-variation'"
              >
                <b-col
                  v-if="!subscribersSelection"
                  align="center"
                  class="d-flex justify-content-center align-items-center"
                >
                <span
                  @click="removeBlockPriceVariation(variation)"
                  class="pointer align-items-center align-content-center fa fa-minus text-danger-light mt-4"
                />
                </b-col>
                <b-col :cols="!subscribersSelection ? 7 : ''">
                  <label class="field-name-class">
                    {{ $t('components.form.playground.block-price.step-1.subscription-rates.subscription-label') }}
                  </label>
                  <div class="d-flex flex-row align-content-center align-items-center">
                    <select
                      v-model="variation.subscriptionPlan"
                      ref="subscription-options-ref"
                      id="subscription-options-id"
                      class="form-control background-light-blue-inputs custom-select-icon-option"
                    >
                      <option disabled :value="null"> {{ $t('general.actions.choose') }}</option>
                      <template v-for="(subscriptionPlan, iterator) of subscriptionPlans">
                        <option
                          :key="'subscriptionPlan - ' + iterator"
                          :value="subscriptionPlan['@id']"
                          :disabled="isActiveSubscriptionPlanOption(subscriptionPlan)"
                        >
                          {{ subscriptionPlan.name }}
                        </option>
                      </template>
                    </select>
                  </div>
                  <b-form-invalid-feedback
                    :state="variation.subscriptionPlan !== null"
                  >
                    {{ $t('validation.required') }}
                  </b-form-invalid-feedback>
                </b-col>
                <b-col cols="4">
                  <label class="field-name-class">
                    {{ $t('components.form.playground.block-price.step-1.subscription-rates.pricePerParticipant') }}
                  </label>
                  <div class="d-flex flex-row align-content-center align-items-center">
                    <b-input-group>
                      <b-form-input
                        type="number"
                        min="0"
                        v-model="variation.pricePerParticipant"
                        size="sm"
                        autocomplete="off"
                        class="background-light-blue-inputs"
                        :placeholder="$t('general.actions.numbers.zero')"
                      >
                      </b-form-input>
                      <b-input-group-append is-text>
                        <div>{{ $currency }}</div>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                  <b-form-invalid-feedback
                    :state="parseFloat(variation.pricePerParticipant) >= 0"
                  >
                    {{ $t('validation.required') }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
            </template>
          </div>
          <b-row v-if="blockPriceVariations.length === 0">
            <b-col align="center">
              <label>
                {{ $t('components.form.playground.block-price.step-1.subscription-rates.no-variations') }}
              </label>
            </b-col>
          </b-row>
          <div v-if="!subscribersSelection" class="border-bottom-grey mt-3 mb-3"/>
          <AddRate
            v-if="!subscribersSelection"
            text-link="components.form.subscriptions.new.add-new-subscription-rate"
            class-icon="fa fa-plus text-blue-darken"
            :pointer="blockPriceVariations.length < subscriptionPlans.length ? 'pointer' : ''"
            :class-icon="blockPriceVariations.length < subscriptionPlans.length ? 'fa fa-plus text-blue-darken' : 'fa fa-plus text-blue-disabled'"
            @on:add-link-click="addNewSubscriptionRate"
          />
        </div>
        <div
          v-else
          class="d-flex align-content-center align-items-center justify-content-center"
        >
          <d-custom-loader/>
        </div>
        <category-modal
          :display="displayModalCategory"
          @on:category-added="loadCategories()"
        />
      </template>

      <b-row class="mt-3 mb-2">
        <b-col cols="8">
          <label class="simple-label-avenir">
            {{ $t('components.custom.playground.new.step-2.timezone.instalment') }}
          </label>
        </b-col>
        <b-col cols="4">
          <div class="d-flex flex-row align-content-center align-items-center">
            <b-input-group>
              <b-form-input
                v-model.number="blockPrice.instalmentAmount"
                :placeholder="$t('general.actions.numbers.zero')"
                min="0"
                size="sm"
                type="number"
                autocomplete="off"
                class="background-light-blue-inputs"
                @input="($value) => (blockPrice.instalmentAmount = parseFloat($value) || null) "
              >
              </b-form-input>
              <b-input-group-append is-text>
                <div>{{ $currency }}</div>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>
<script>
import CategoryModal from "@custom/playground/new/step-1/CategoryModal";
import PriceCategory from "@/classes/doinsport/PriceCatgory";
import PriceVariation from "@/classes/doinsport/PriceVariation";
import {refine} from "@/utils/classes";
import {fromIdToIriReference} from "@/utils/form";
import {getClubForms} from "@api/doinsport/services/form/form.api";
import {getClubCategories} from "@api/doinsport/services/participant/participant.api";
import {EVERYONE_REGISTRATION, FORMULA_TYPE, LESSON_TYPE, SUBSCRIBERS_REGISTRATION} from "@/classes/doinsport/Price";
import {URI_SUBSCRIPTION_PLAN} from "@api/doinsport/services/subscription-plans/subscription-plans.api";
import {getPriceCategories, getPriceVariations} from "@api/doinsport/services/timetable/blocks/price/price.api";

const BLOCK_PRICE_OBSERVER = "blockPriceStep2Observer"

export default {
  data: () => ({
    validation: require('@validation/entities/doinsport/Price'),
    categories: [],
    forms: [],
    displayModalCategory: false,
  }),
  props: {
    deletedBlockPriceVariations: {
      type: Array,
      default: () => [],
    },
    deletedBlockPriceCategories: {
      type: Array,
      default: () => [],
    },
    step1Validator: {
      type: Boolean,
      default: false
    },
    isLoadingSubscriptionPlans: {
      type: Boolean,
      default: false
    },
    blockPrice: {
      type: Object,
      default: () => {
      },
    },
    blockPriceCategories: {
      type: Array,
      default: () => [],
    },
    subscriptionPlans: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    CategoryModal,
    AddRate: () => import('@custom/AddLink')
  },
  computed: {
    subscribersSelection() {
      return this.blockPrice.activityType === LESSON_TYPE && this.blockPrice.registrationAvailableFor === SUBSCRIBERS_REGISTRATION;
    },
    isValidPublicPrice() {
      if (this.blockPrice.activityType === LESSON_TYPE && this.blockPrice.registrationAvailableFor !== EVERYONE_REGISTRATION) {
        return false;
      }

      return true;
    },
    marginSection() {
      return this.blockPrice.activityType === 'lesson' ? 'mt-0' : 'mt-3';
    },
    blockPriceVariations() {
      return this.$store.getters["blockPrice/getBlockPriceVariations"];
    },
    observerRef() {
      return BLOCK_PRICE_OBSERVER;
    },
  },
  watch: {
    step1Validator: function () {
      let validator = true;

      for (const blockPriceVariation of this.blockPriceVariations) {
        if (!(parseFloat(blockPriceVariation.pricePerParticipant) >= 0)) {
          validator = false;
          break;
        }
        if (blockPriceVariation.subscriptionPlan === null) {
          validator = false;
          break;
        }
      }

      if (validator) {
        if (this.blockPrice.activityType === 'leisure') {
          for (const blockPriceCategory of this.blockPriceCategories) {
            if (!(parseFloat(blockPriceCategory.pricePerParticipant) >= 0)) {
              validator = false;
              break;
            }
            if (blockPriceCategory.category === null) {
              validator = false;
              break;
            }
          }
        } else {
          if (this.blockPrice.pricePerParticipant === null) {
            validator = false;
          }
        }

        if ((this.blockPrice.form === '' || this.blockPrice.form === null) && this.blockPrice.activityType === FORMULA_TYPE) {
          validator = false;
        }
      }

      if (validator) {
        this.$emit('on:step-2-from:validated');
      }
    }
  },
  methods: {
    isActiveSubscriptionPlanOption(subscriptionPlan) {
      const variation = this.blockPriceVariations.find(el => el.subscriptionPlan === subscriptionPlan['@id']);
      return "undefined" !== typeof variation;
    },
    isActiveCategoryOption(category) {
      const categoryObject = this.blockPriceCategories.find(el => el.category === category['@id']);
      return "undefined" !== typeof categoryObject;
    },
    removeBlockPriceCategory(blockPriceCategory) {
      const findInCategories = this.blockPriceCategories.find(el => el.id === blockPriceCategory.id);

      if ("undefined" !== typeof findInCategories) {
        if (typeof (blockPriceCategory.recent) === "undefined") {
          this.deletedBlockPriceCategories.push(blockPriceCategory);
        }

        this.blockPriceCategories.splice(this.blockPriceCategories.indexOf(findInCategories), 1);
      }
    },
    notADeletedCategory(blockPriceCategory) {
      const findInDeletedBlockPriceCategories = this.deletedBlockPriceCategories.find((el) => el.id === blockPriceCategory.id);
      return "undefined" === typeof findInDeletedBlockPriceCategories;
    },
    addNewRate() {
      if (this.blockPriceCategories.length < this.categories.length) {
        this.newBlockPriceCategory();
      }
    },
    loadCategories() {
      this.categories = [];

      if (this.blockPrice.activityType === 'leisure') {
        getClubCategories(500)
          .then((response) => {
            this.categories = response.data['hydra:member'];
          })
        ;
      }
    },
    loadForms() {
      this.forms = [];
      getClubForms(500)
        .then((response) => {
          this.forms = response.data['hydra:member'];
        })
      ;
    },
    addNewSubscriptionRate() {
      if (this.blockPriceVariations.length < this.subscriptionPlans.length) {
        const blockPriceVariation = new PriceVariation();
        blockPriceVariation.recent = true;

        this.$store.dispatch('blockPrice/addBlockPriceVariation', blockPriceVariation);
      }
    },
    removeBlockPriceVariation(blockPriceVariation) {
      if ("undefined" === typeof blockPriceVariation.recent) {
        this.deletedBlockPriceVariations.push(blockPriceVariation);
      }
      this.$store.dispatch('blockPrice/removeBlockPriceVariation', blockPriceVariation);
    },
    newBlockPriceCategory() {
      const categoryPrice = new PriceCategory();
      categoryPrice.recent = true;

      this.blockPriceCategories.push(categoryPrice);
    },
    initBlockPriceCategories() {
      if (this.blockPrice.activityType === 'leisure') {
        if (this.blockPrice.id === null) {
          if (this.blockPriceCategories.length === 0) {
            this.newBlockPriceCategory();
          }
        } else {
          getPriceCategories(this.blockPrice.id, 100)
            .then((response) => {
              for (const blockPriceCategory of response.data['hydra:member']) {
                refine(blockPriceCategory);
                blockPriceCategory.pricePerParticipant = blockPriceCategory.pricePerParticipant / 100;

                const alreadyAdded = this.blockPriceCategories.find((el) => el.id === blockPriceCategory.id);

                if ("undefined" === typeof alreadyAdded) {
                  this.blockPriceCategories.push(blockPriceCategory);
                }
              }
            })
            .finally(() => {
              if (this.blockPriceCategories.length === 0) {
                this.newBlockPriceCategory();
              }
            })
          ;
        }
      }
    },
    initBlockPriceVariations() {
      if (this.blockPrice.id === null) {
        this.$store.dispatch('blockPrice/saveBlockPriceVariations', []);
        this.checkIfValidPriceVariation();
      } else {
        if (this.blockPrice.variations.length > 0) {
          this.$store.dispatch('blockPrice/saveBlockPriceVariations', []);

          getPriceVariations(this.blockPrice.id, 100)
            .then((response) => {
              for (const blockPriceVariation of response.data['hydra:member']) {
                refine(blockPriceVariation);
                blockPriceVariation.pricePerParticipant = blockPriceVariation.pricePerParticipant / 100;
                this.$store.dispatch('blockPrice/addBlockPriceVariation', blockPriceVariation);
                this.checkIfValidPriceVariation(blockPriceVariation);
              }
            })
            .finally(() => {
              this.filterVariationsByAllowedSubscriptionPlans();
            })
          ;
        } else {
          this.filterVariationsByAllowedSubscriptionPlans();
        }
      }
    },
    checkIfValidPriceVariation(blockPriceVariation = null) {
      if (this.blockPrice.activityType === LESSON_TYPE && this.blockPrice.registrationAvailableFor === SUBSCRIBERS_REGISTRATION) {
        if (null !== this.blockPrice.id) {
          const findInAllowedSubscriptions = this.blockPrice.allowedSubscriptionPlans.find(el => fromIdToIriReference(URI_SUBSCRIPTION_PLAN, el.code) === blockPriceVariation.subscriptionPlan);

          if ("undefined" === typeof findInAllowedSubscriptions) {
            this.removeBlockPriceVariation(blockPriceVariation);
          }
        } else {
          this.filterVariationsByAllowedSubscriptionPlans();
        }
      }
    },
    filterVariationsByAllowedSubscriptionPlans() {
      if (this.blockPrice.activityType === LESSON_TYPE && this.blockPrice.registrationAvailableFor === SUBSCRIBERS_REGISTRATION) {
        for (const allowedSubscriptionPlan of this.blockPrice.allowedSubscriptionPlans) {
          const iriSubscriptionPlan = fromIdToIriReference(URI_SUBSCRIPTION_PLAN, allowedSubscriptionPlan.code);
          const checkIfNotAdded = this.blockPriceVariations.find(el => el.subscriptionPlan === iriSubscriptionPlan);

          if ("undefined" === typeof checkIfNotAdded) {
            const blockPriceVariation = new PriceVariation();
            blockPriceVariation.subscriptionPlan = iriSubscriptionPlan;
            blockPriceVariation.recent = true;

            this.$store.dispatch('blockPrice/addBlockPriceVariation', blockPriceVariation);
          }
        }
      }
    }
  },
  created() {
    this.loadForms();
    this.loadCategories();
    this.initBlockPriceCategories();
    this.initBlockPriceVariations();
  }
}
</script>

<style lang="scss" scoped>
@import "@lazy/form/_block-price.scss";

.custom-left-borders {
  border-bottom-left-radius: unset;
  border-top-left-radius: unset;
}

.btn-custom-size {
  font-size: 14px;
  padding: 0.375rem 0.9rem;
  border-bottom-right-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}

.custom-right-borders {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.custom-right-borders-select {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  height: max-content;
  padding: 0.32rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.custom-select-icon-option {
  height: max-content;
  padding: 0.32rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

/deep/ .input-group-text {
  line-height: 0;
  color: #0B2772;
}

.container-scroll {
  position: relative;
  max-height: 340px;
  overflow-x: scroll;
}
</style>
