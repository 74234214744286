import {clubId, _get, _delete, _post, _put} from "@api/doinsport/services/httpService";

const URI = "/clubs/forms"

export const getClubForms = (itemsPerPage) => _get(URI + '?club.id=' +clubId + '&itemsPerPage=' + itemsPerPage);

export const deleteClubForm = (id) => _delete(URI + '/' + id);

export const postClubForm = (form) => _post(URI, form);

export const putClubForm = (id, data) => _put(URI + '/' + id, data);
