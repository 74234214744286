<template>
  <d-side-bar
    :id="modalId"
    :model="display"
    :identifier="'bookingModal' + modalId"
    width="820px"
    @shown="$emit('shown')"
    @hidden="$emit('hidden')"
    @change="$emit('change')"
  >
    <div slot="header">
      <b-row>
        <b-col>
          <label class="block-price-title ml-4">
            {{ $t('views.playground.steps.step-1.informations.activities.table.block-price-label') }}
          </label>
        </b-col>
      </b-row>
    </div>
    <div
      v-if="display"
      slot="body"
      class="p-4 custom-sidebar-height"
    >
      <b-row>
        <b-col>
          <step1-form
            v-if="step === 0"
            :playground="playground"
            :block-price="blockPrice"
            :step0-validator="step0Validator"
            :playground-activities="playgroundActivities"
            @on:step-1-from:validated="onStep1Validated"
          />
          <step2-form
            v-if="step === 1"
            :block-price="blockPrice"
            :step1-validator="step1Validator"
            :subscription-plans="subscriptionPlans"
            :block-price-categories="blockPriceCategories"
            :deleted-block-price-categories="deletedBlockPriceCategories"
            :deleted-block-price-variations="deletedBlockPriceVariations"
            :is-loading-subscription-plans="isLoadingSubscriptionPlans"
            @on:step-2-from:validated="onStep2Validated"
          />
          <step3-form
            v-if="step === 2"
            :block-price="blockPrice"
            :step2-validator="step2Validator"
            :subscription-plans="subscriptionPlans"
            :deleted-block-price-variations="deletedBlockPriceVariations"
            :deleted-block-price-categories="deletedBlockPriceCategories"
            :deleted-block-price-payment-tokens="deletedBlockPricePaymentTokens"
            :deleted-single-payment-token-prices="deletedNoVariationsPaymentTokenPrices"
            @on:step-3-from:validated="onStep3Validated"
          />
        </b-col>
      </b-row>
    </div>
    <div class="footer-bottom pl-4 pr-4" slot="footer">
      <div class="border-bottom-grey-dark mt-3 mb-3"/>
      <b-row>
        <b-col
          class="custom-col-margin"
          cols="5"
          align="left"
        >
          <steps
            :step="step"
            :is-step4="false"
            :is-step5="false"
            step2-margin="20"
            step3-margin="13"
            max-steps="2"
            step0-label="components.custom.playground.new.step-2.block-price.step-0.title"
            step1-label="components.custom.playground.new.step-2.block-price.step-1.title"
            step2-label="components.custom.playground.new.step-2.block-price.step-2.title"
            @on:step-0:selected="step = 0"
            @on:step-1:selected="step = 1"
          />
        </b-col>
        <b-col align="right">
          <d-button
            :text="step < 2 ? 'general.actions.next-step' : 'general.actions.save'"
            class="d-btn-sm font-text-title d-btn btn d-btn-danger font-text-title mt-2"
            icon-position="right"
            @on:button-click="onNextClick"
          />
        </b-col>
      </b-row>
    </div>
  </d-side-bar>
</template>
<script>
import Step1Form from "@form/playground/new/step-2/block-price/Step1Form";
import Step2Form from "@form/playground/new/step-2/block-price/Step2Form";
import Step3Form from "@form/playground/new/step-2/block-price/Step3Form";
import {getSubscriptionPlans} from "@api/doinsport/services/subscription-plans/subscription-plans.api";
import {getPricePaymentTokens, postPrice, putPrice} from "@api/doinsport/services/timetable/blocks/price/price.api";
import Price, {
  ALL_SUBSCRIBERS_REGISTRATION,
  EVERYONE_REGISTRATION,
  LESSON_TYPE,
  SUBSCRIBERS_REGISTRATION
} from "@/classes/doinsport/Price";
import {
  deleteBlockPriceVariation,
  postBlockPriceVariation,
  putBlockPriceVariation
} from "@api/doinsport/services/timetable/blocks/price/variation/variation.api";
import {
  deleteBlockPriceParticipantCategory,
  postBlockPriceParticipantCategory,
  putBlockPriceParticipantCategory
} from "@api/doinsport/services/timetable/blocks/price/participant-category/participant-category.api";
import {
  deleteBlockPricePaymentTokenPrice,
  postBlockPricePaymentTokenPrice,
  putBlockPricePaymentTokenPrice
} from "@api/doinsport/services/timetable/blocks/price/payment-token-price/payment-token-price.api";
import {getDuration} from "@/utils/date";
import {SUCCESS} from "@plugins/flash";

const MODAL_ID = 'block-price-modal-id';

export default {
  data: () => ({
    step: 0,
    inProgress: false,
    step0Validator: false,
    step1Validator: false,
    step2Validator: false,
    isLoadingSubscriptionPlans: false,
    subscriptionPlans: [],
    blockPriceCategories: [],
    deletedBlockPriceVariations: [],
    deletedBlockPriceCategories: [],
    blockPriceVariationsResponse: [],
    blockPricePaymentTokensPrices: [],
    deletedBlockPricePaymentTokens: [],
    deletedNoVariationsPaymentTokenPrices: [],
  }),
  components: {
    Step1Form,
    Step2Form,
    Step3Form,
    Steps: () => import('@views/playground/steps/Menus')
  },
  props: {
    display: {
      type: Boolean,
      default: false,
    },
    playground: {
      type: Object,
      default: () => {
      }
    },
    blockPrice: {
      type: Object,
      default: () => {
      }
    },
    playgroundActivities: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    hasSubscribers() {
      if (this.blockPrice.activityType === LESSON_TYPE) {
        if (this.blockPrice.registrationAvailableFor === EVERYONE_REGISTRATION) {
          return true;
        }

        if (this.blockPrice.registrationAvailableFor === SUBSCRIBERS_REGISTRATION || this.blockPrice.registrationAvailableFor === ALL_SUBSCRIBERS_REGISTRATION) {
          return false;
        }
      }

      return true;
    },
    modalId() {
      return MODAL_ID;
    }
  },
  watch: {
    display: function () {
      this.step = 0;
      this.deletedBlockPriceCategories = [];
      this.deletedBlockPriceVariations = [];
      this.deletedBlockPricePaymentTokens = [];
      this.deletedNoVariationsPaymentTokenPrices = [];
      this.loadPaymentTokenPrices();
    }
  },
  methods: {
    createOrUpdateBlockPrice(blockPrice) {
      return blockPrice.id ? putPrice(blockPrice) : postPrice(blockPrice);
    },
    onNextClick() {
      switch (this.step) {
        case 0:
          this.step0Validator = !this.step0Validator;
          break;
        case 1:
          this.step1Validator = !this.step1Validator;
          break;
        default:
          this.step2Validator = !this.step2Validator;
      }
    },
    loadSubscriptions() {
      this.subscriptionPlans = [];
      if (!this.isLoadingSubscriptionPlans) {
        this.isLoadingSubscriptionPlans = true;

        getSubscriptionPlans(100, 1, '', null)
          .then((response) => {
            if (this.blockPrice.activityType === 'lesson') {
              if (this.blockPrice.registrationAvailableFor === 'subscribers') {
                for (const subscriptionPlan of response.data['hydra:member']) {
                  const checkValidSubscriptionPlan = this.blockPrice.allowedSubscriptionPlans.find(el => el.code === subscriptionPlan.id);

                  if ("undefined" !== typeof checkValidSubscriptionPlan) {
                    this.subscriptionPlans.push(subscriptionPlan);
                  }
                }
              } else {
                this.subscriptionPlans = response.data['hydra:member'];
              }
            } else {
              this.subscriptionPlans = response.data['hydra:member'];
            }
          })
          .finally(() => {
            this.isLoadingSubscriptionPlans = false;
          })
        ;
      }
    },
    onStep1Validated() {
      this.loadSubscriptions();
      this.step++;
    },
    onStep2Validated() {
      this.step++;
    },
    onStep3Validated(paymentTokenPrices) {
      if (!this.inProgress) {
        this.inProgress = true;
        const serializedBlockPrice = new Price(this.blockPrice, {serialize: true});
        serializedBlockPrice.playgroundOptions = this.blockPrice.playgroundOptions;
        // Create Or Update new BlockPrice Step1
        this.createOrUpdateBlockPrice(serializedBlockPrice)
          .then((blockPrice) => {
            const priceClass = new Price(blockPrice.data, {serialize: false, deserialize: true});
            if (this.blockPrice.id === null && null !== this.blockPrice.mainPhoto) {
              const payload = {entry: 'blockPrice', url: blockPrice.data['@id'], target: 'postBlockPricePhoto'};
              this.$uploadFile(this.blockPrice.mainPhoto, payload);
            }
            Object.assign(priceClass, {minusIcon: true});
            Object.assign(priceClass, {nicheDuration: getDuration(priceClass.duration)});
            Object.assign(priceClass, {otherPrices: this.$t('general.actions.details')});

            this.$store.dispatch('playgrounds/updateBlockPrice', priceClass);

            const blockPriceVariations = this.$store.getters["blockPrice/getBlockPriceVariations"];
            const requests = [];

            // Create Or Update All blockPriceVariations Step2
            for (const blockPriceVariation of blockPriceVariations) {
              blockPriceVariation.recent ?
                requests.push(
                  postBlockPriceVariation(
                    {
                      pricePerParticipant: Math.round((parseFloat(blockPriceVariation.pricePerParticipant, 2).toFixed(2)) * 100),
                      blockPrice: blockPrice.data['@id'],
                      subscriptionPlan: blockPriceVariation.subscriptionPlan,
                    })
                )
                :
                requests.push(
                  putBlockPriceVariation(
                    {
                      id: blockPriceVariation.id,
                      pricePerParticipant: Math.round((parseFloat(blockPriceVariation.pricePerParticipant, 2).toFixed(2)) * 100),
                      subscriptionPlan: blockPriceVariation.subscriptionPlan,
                    })
                )
              ;
            }

            // When all requests succeeds Create Or Update all payment tokenPrices Step3

            this.blockPriceVariationsResponse = [];

            Promise.all(requests)
              .then(results => this.blockPriceVariationsResponse = results.map(result => result.data))
              .finally(() => {
                if (paymentTokenPrices.length > 0) {
                  for (const paymentTokenPrice of paymentTokenPrices) {
                    if (paymentTokenPrice.recent) {
                      postBlockPricePaymentTokenPrice(
                        {
                          pricePerParticipant: parseFloat(paymentTokenPrice.publicPrice),
                          paymentToken: paymentTokenPrice.paymentToken,
                          blockPrice: blockPrice.data['@id'],
                        })
                      ;
                    } else {
                      putBlockPricePaymentTokenPrice(
                        {
                          id: paymentTokenPrice.id,
                          paymentToken: paymentTokenPrice.paymentToken,
                          pricePerParticipant: parseFloat(paymentTokenPrice.publicPrice),
                        }
                      );
                    }

                    for (const blockVariation of paymentTokenPrice.blockPriceVariations) {
                      if (blockVariation.recent) {
                        postBlockPricePaymentTokenPrice(
                          {
                            pricePerParticipant: parseFloat(blockVariation.pricePerParticipant),
                            paymentToken: paymentTokenPrice.paymentToken,
                            blockPriceVariation: this.blockPriceVariationsResponse.find(el => el.subscriptionPlan['@id'] === blockVariation.subscriptionPlan)['@id'],
                          })
                        ;
                      } else {
                        putBlockPricePaymentTokenPrice(
                          {
                            id: blockVariation.id,
                            paymentToken: paymentTokenPrice.paymentToken,
                            pricePerParticipant: parseFloat(blockVariation.pricePerParticipant),
                          }
                        );
                      }
                    }
                  }
                }
              })
            ;
            // Create Or Update All blockPriceCategories when activity type is leisure Step2

            if (this.blockPrice.activityType === 'leisure') {
              for (const blockPriceCategory of this.blockPriceCategories) {
                if (blockPriceCategory.recent) {
                  postBlockPriceParticipantCategory(
                    {
                      pricePerParticipant: parseInt(parseFloat(blockPriceCategory.pricePerParticipant) * 100),
                      blockPrice: blockPrice.data['@id'],
                      category: blockPriceCategory.category,
                      form: blockPriceCategory.form
                    })
                  ;
                } else {
                  putBlockPriceParticipantCategory(
                    {
                      id: blockPriceCategory.id,
                      pricePerParticipant: parseInt(parseFloat(blockPriceCategory.pricePerParticipant) * 100),
                      blockPrice: blockPrice.data['@id'],
                      category: blockPriceCategory.category,
                      form: blockPriceCategory.form
                    })
                  ;
                }
              }
              // Remove deleted blockPriceCategories

              const deletedBlockPriceCategoriesRequests = [];

              for (const blockPriceCategoryToDelete of this.deletedBlockPriceCategories) {
                deletedBlockPriceCategoriesRequests.push(deleteBlockPriceParticipantCategory(blockPriceCategoryToDelete.id));
              }
              if (deletedBlockPriceCategoriesRequests.length > 0) {
                Promise.all(deletedBlockPriceCategoriesRequests);
              }
            }
          }).finally(() => {
          this.$flash(null, this.$t('general.actions.success-update'), 5000, SUCCESS);

          // Remove deleted blockPriceVariations

          const deletedBlockPriceVariationsRequests = [];

          for (const blockPriceVariationToDelete of this.deletedBlockPriceVariations) {
            deletedBlockPriceVariationsRequests.push(deleteBlockPriceVariation(blockPriceVariationToDelete.id));
          }
          if (deletedBlockPriceVariationsRequests.length > 0) {
            Promise.all(deletedBlockPriceVariationsRequests);
          }
          // Remove deleted blockPricePaymentTokens that doesnt contain a subscriptionPlanVariation

          const deletedPaymentTokenPricesRequests = [];

          for (const blockPaymentTokenToDelete of this.deletedBlockPricePaymentTokens) {
            for (const blockPricePaymentTokenPrice of this.blockPricePaymentTokensPrices) {
              if (blockPaymentTokenToDelete.paymentToken === blockPricePaymentTokenPrice.paymentToken) {

                deletedPaymentTokenPricesRequests.push(deleteBlockPricePaymentTokenPrice(blockPricePaymentTokenPrice.id));
              }
            }
          }

          if (deletedPaymentTokenPricesRequests.length > 0) {
            Promise.all(deletedPaymentTokenPricesRequests);
          }

          this.inProgress = false;
          this.$emit('reload:block-prices');
        });
      }
    },
    loadPaymentTokenPrices() {
      if (this.blockPrice.id) {
        getPricePaymentTokens(this.blockPrice.id)
          .then((response) => {
            this.blockPricePaymentTokensPrices = response.data['hydra:member'];
          })
        ;
      }
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@lazy/_modal.scss";

.custom-col-margin {
  margin-left: -35px;
}

.footer-bottom {
  position: absolute;
  bottom: 75px;
  width: -webkit-fill-available;
}

.block-price-title {
  text-align: left;
  font: normal normal 700 25px Avenir;
  letter-spacing: 0.78px;
  color: #092772;
  opacity: 1;
}

.custom-sidebar-height {
  height: calc(100% - 240px);
  overflow: scroll;
}
</style>
