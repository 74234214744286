<template>
  <div>
    <validation-observer
      slim
      :ref="observerRef"
    >
      <b-row>
        <b-col>
          <label class="title-step">
            {{ $t('components.form.playground.block-price.step-0.general-information.title') }}
          </label>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-if="isNotSportActivity"
          cols="3"
        >
          <div class="product-box mt-2">
            <div class="product-img">
              <img
                v-if="null !== fileToken"
                :src="fileToken"
                height="135"
                width="135"
                class="object-fit-img img-border"
                alt
              />
              <img
                class="pointer"
                v-else
                height="130"
                width="130"
                src="@/assets/icons/playground/new/step-1/upload-image.svg"
                alt
              />
              <div class="product-hover">
                <ul
                  v-if="fileToken === null"
                >
                  <li
                    class="pointer"
                    @click="uploadPhoto"
                  >
                    <button
                      class="btn"
                      type="button"
                    >
                      <i class="icon-upload"></i>
                    </button>
                  </li>
                </ul>
                <ul v-else>
                  <li
                    class="pointer"
                    @click="resetPhoto"
                  >
                    <button
                      class="btn"
                      type="button"
                    >
                      <i class="icon-trash"></i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </b-col>
        <b-col
          :cols="cols('general_section')"
        >
          <b-row>
            <b-col
              :cols="blockPrice.activityType === 'leisure' || blockPrice.activityType === 'formula' ? 6 : 12"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="field-name-class"
                >
                  {{ $t('components.form.playground.block-price.step-0.general-information.name.label') }}
                </b-col>
                <b-col class="mt-2">
                  <d-text-field
                    v-model="blockPrice.name"
                    :rules="validation.name"
                    place-holder="components.form.playground.block-price.step-0.general-information.name.placeholder"
                    class-name="background-light-blue-inputs"
                    size="sm"
                    error-text="validation.text-input-three-characters"
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col
              v-if="blockPrice.activityType === 'leisure' || blockPrice.activityType === 'formula'"
              cols="6"
            >
              <b-row>
                <b-col
                  class="field-name-class"
                  cols="12"
                >
                  {{ $t('components.form.playground.block-price.step-0.general-information.label.label') }}
                </b-col>
                <b-col class="mt-2">
                  <d-text-field
                    v-model="blockPrice.label"
                    :rules="validation.label"
                    size="sm"
                    class-name="background-light-blue-inputs"
                    error-text="validation.text-input-five-characters"
                    place-holder="components.form.playground.block-price.step-0.general-information.label.placeholder"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col cols="6">
              <b-row>
                <b-col cols="12">
                  <label class="field-name-class">
                    {{ $t('components.form.prices.type.label') }}
                  </label>
                </b-col>
                <b-col cols="12">
                  <select
                    v-model="blockPrice.activityType"
                    :disabled="blockPrice.id"
                    class="form-control custom-select-option background-light-blue-inputs"
                    size="sm"
                    @change="onActivityTypeUpdate"
                  >
                    <option disabled :value="null"> {{ $t('general.actions.choose') }}</option>
                    <option
                      v-if="isUniqueBookingType"
                      value="sport"
                    >
                      {{ $t('components.form.playground.block-price.activityType.sport') }}
                    </option>
                    <option
                      v-else
                      value="leisure"
                    >
                      {{ $t('components.form.playground.block-price.activityType.leisure') }}
                    </option>
                    <option value="lesson">
                      {{ $t('components.form.playground.block-price.activityType.lesson') }}
                    </option>
                    <option value="formula">
                      {{ $t('components.form.playground.block-price.activityType.formula') }}
                    </option>
                  </select>
                  <b-form-invalid-feedback
                    :state="blockPrice.activityType !== null"
                    class="invalid-feedback"
                  >
                    {{ $t('validation.required') }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="6">
              <b-row>
                <b-col cols="12">
                  <label class="field-name-class">
                    {{ $t('components.form.prices.activity') }}
                  </label>
                </b-col>
                <b-col cols="12">
                  <select
                    v-model="blockPrice.activity"
                    class="custom-select-option form-control background-light-blue-inputs"
                    size="sm"
                    @change="reloadLevels = !reloadLevels"
                  >
                    <option
                      :value="null"
                      disabled
                    >
                      {{ $t('general.actions.choose') }}
                    </option>
                    <template v-for="(activity, iterator) of playgroundActivities ">
                      <option
                        :key="'activity' + iterator"
                        :value="activity['@id']"
                      >
                        {{ activity.name }}
                      </option>
                    </template>
                  </select>
                  <b-form-invalid-feedback
                    :state="blockPrice.activity !== null"
                  >
                    {{ $t('validation.required') }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          :cols="cols('participants_section')"
        >
          <b-row>
            <b-col
              v-if="blockPrice.activityType === 'formula'"
              :cols="6"
              class="field-name-class"
            >
              <label>
                {{ $t('components.form.prices.minParticipantsCountLimit.minParticipantsCountLimit') }}
              </label>
              <d-text-field
                v-model="blockPrice.minParticipantsCountLimit"
                :rules="validation.minParticipantsCountLimit"
                :min="0"
                size="sm"
                type="number"
                place-holder="general.actions.numbers.zero"
                class-name="background-light-blue-inputs"
                error-text="validation.is-a-number"
              />
            </b-col>
            <b-col
              :cols="blockPrice.activityType === 'leisure' || blockPrice.activityType === 'formula' ? 6 : 12"
              class="field-name-class"
            >
              <label>
                {{
                  blockPrice.activityType === 'sport' ? $t('components.form.prices.maxParticipantsCountLimit.label') : $t('components.form.prices.maxParticipantsCountLimit.maxParticipantsCountLimit')
                }}
              </label>
              <d-text-field
                v-model="blockPrice.maxParticipantsCountLimit"
                :rules="validation.maxParticipantsCountLimit"
                :min="0"
                size="sm"
                type="number"
                place-holder="general.actions.numbers.zero"
                class-name="background-light-blue-inputs"
                error-text="validation.is-a-number"
              />
            </b-col>
            <b-col
              v-if="blockPrice.activityType === 'leisure'"
              :cols="blockPrice.activityType === 'leisure' ? 6 : 12"
              class="field-name-class"
            >
              <label>
                {{
                  $t('components.form.playground.block-price.step-0.general-information.simultaneous-bookings.label')
                }}
              </label>
              <d-text-field
                v-model.number="blockPrice.maxBookingCountLimit"
                :rules="validation.maxParticipantsCountLimit"
                min="0"
                type="number"
                place-holder="general.actions.numbers.zero"
                class-name="background-light-blue-inputs"
                size="sm"
                error-text="validation.is-a-number"
              />
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              v-if="blockPrice.activityType === 'formula'"
              :cols="6"
              class="field-name-class"
            >
              <label>
                {{
                  $t('components.form.playground.block-price.step-0.general-information.simultaneous-bookings.label')
                }}
              </label>
              <d-text-field
                v-model.number="blockPrice.maxBookingCountLimit"
                :rules="validation.maxParticipantsCountLimit"
                min="1"
                type="number"
                place-holder="general.actions.hyphen"
                class-name="background-light-blue-inputs"
                size="sm"
                error-text="validation.is-a-number"
              />
            </b-col>
            <b-col
              :cols="blockPrice.activityType === 'leisure' || blockPrice.activityType === 'formula' ? 6 : 12"
              class="field-name-class"
            >
              <label>
                {{ $t('components.form.prices.duration') }}
              </label>
              <d-text-field
                v-model.number="blockPrice.duration"
                :rules="validation.duration"
                min="5"
                size="sm"
                type="number"
                error-text="validation.is-a-number"
                place-holder="general.actions.hyphen"
                class-name="background-light-blue-inputs"
              />
            </b-col>
            <b-col
              v-if="blockPrice.activityType === 'leisure'"
              :cols="blockPrice.activityType === 'leisure' ? 6 : 12"
              class="field-name-class"
            >
              <label>
                {{ $t('components.form.playground.block-price.step-0.general-information.category.label') }}
              </label>

              <select
                v-model="blockPrice.category"
                class="form-control form-control-sm background-light-blue-inputs"
                size="sm"
              >
                <option
                  :value="null"
                  disabled
                >
                  {{ $t('general.actions.choose') }}
                </option>
                <template v-for="(category, i) of categories">
                  <option
                    :key="`category-${i}`"
                    :value="category['@id']"
                  >
                    {{ category.name }}
                  </option>
                </template>
              </select>
              <b-form-invalid-feedback
                :state="blockPrice.category !== null"
              >
                {{ $t('validation.required') }}
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-if="blockPrice.activityType !== 'leisure'">
        <b-col
          :cols="blockPrice.activityType === 'lesson' ? 6 : 12"
          class="field-name-class mt-2"
        >
          <label>
            {{ $t('components.form.playground.block-price.step-0.general-information.category.label') }}
          </label>

          <select
            v-model="blockPrice.category"
            class="form-control form-control-sm background-light-blue-inputs"
            size="sm"
          >
            <option
              :value="null"
              disabled
            >
              {{ $t('general.actions.choose') }}
            </option>
            <template v-for="(category, i) in categories">
              <option
                :key="`category - ${i}`"
                :value="category['@id']"
              >
                {{ category.name }}
              </option>
            </template>
          </select>
          <b-form-invalid-feedback
            :state="blockPrice.category !== null"
          >
            {{ $t('validation.required') }}
          </b-form-invalid-feedback>
        </b-col>
        <b-col
          v-if="blockPrice.activityType === 'lesson'"
          :cols="6"
          class="d-flex align-content-center align-items-center"
          :class="blockPrice.category ? 'mt-4' : 'mt-3'"
        >
          <vs-checkbox
            v-model="blockPrice.participantsQueueEnabled"
            color="primary"
            value="false"
          />
          <p class="simple-label-avenir mt-3">
            {{ $t('components.form.prices.participantsQueueEnabled.label') }}
          </p>
        </b-col>
      </b-row>
      <b-row
        v-if="isNotSportActivity"
        class="mt-1"
      >
        <b-col
          cols="12"
        >
          <label class="field-name-class">
            {{ $t('components.form.playground.block-price.step-0.general-information.description.label') }}
          </label>
        </b-col>
        <b-col cols="12">
          <email-editor
            v-if="blockPrice.activityType === 'leisure' || blockPrice.activityType === 'formula'"
            :is-empty="true"
            :default-content="blockPrice.description"
            @on:editor-content:update="blockPrice.description = $event"
          />
          <div
            v-else
          >
            <b-textarea
              v-model="blockPrice.description"
              :placeholder="$t('components.form.playground.block-price.step-0.general-information.description.placeholder')"
              size="sm"
              class="background-light-blue-inputs"
              rows="5"
            />
          </div>
        </b-col>
      </b-row>
      <booking-options-form
        v-if="blockPrice.activityType !== 'lesson'"
        :options="options"
        :block-price="blockPrice"
        :is-loading-options="isLoadingOptions"
        @on:option:check="onOptionCheck"
      />
      <online-booking-form
        v-else
        :block-price="blockPrice"
        :reload-levels="reloadLevels"
        :validation="validation"
      />
      <b-row
        class="mt-1"
      >
        <b-col cols="12">
          <span class="title-step pt-0 mt-0">
            {{ $t('components.form.playground.block-price.step-0.general-information.booking-comment.label') }}
          </span>
          <span class="d-inline-block note-feedback mb-2">
            {{ $t('components.form.playground.block-price.step-0.general-information.booking-comment.note') }}
          </span>
        </b-col>
        <b-col cols="12">
          <email-editor
            :is-empty="true"
            :default-content="blockPrice.bookingComment"
            @on:editor-content:update="blockPrice.bookingComment = $event"
          />
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>
<script>
import {fromIdToIriReference} from "@/utils/form";
import {_delete, _get} from "@api/doinsport/services/httpService";
import {UNIQUE_BOOKING_TYPE} from "@/classes/doinsport/Playground";
import {getClubOptions} from "@api/doinsport/services/option/option.api";
import {FORMULA_TYPE, LEISURE_TYPE, LESSON_TYPE, SPORT_TYPE} from "@/classes/doinsport/Price";
import {URI_BLOCK_PRICE} from "@api/doinsport/services/timetable/blocks/price/price.api";
import {getBlockPriceCategories} from "@api/doinsport/services/timetable/blocks/price/category/category.api";

const BLOCK_PRICE_OBSERVER = "blockPriceStep1Observer"

export default {
  components: {
    EmailEditor: () => import("@custom/EmailEditor"),
    OnlineBookingForm: () => import("@form/playground/new/step-1/OnlineBookingForm"),
    BookingOptionsForm: () => import("@form/playground/new/step-1/BookingOptionsForm"),
  },
  data: () => ({
    activityType: SPORT_TYPE,
    reloadLevels: false,
    fileToken: null,
    isLoadingOptions: false,
    options: [],
    categories: [],
    validation: require('@validation/entities/doinsport/Price'),
  }),
  props: {
    step0Validator: {
      type: Boolean,
      default: false
    },
    playground: {
      type: Object,
      default: () => {
      }
    },
    blockPrice: {
      type: Object,
      default: () => {
      }
    },
    playgroundActivities: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isUniqueBookingType() {
      return this.playground.bookingType === UNIQUE_BOOKING_TYPE;
    },
    observerRef() {
      return BLOCK_PRICE_OBSERVER;
    },
    isNotSportActivity() {
      const activityType = this.blockPrice.activityType;

      return activityType === LEISURE_TYPE || activityType === LESSON_TYPE || activityType === FORMULA_TYPE;
    }
  },
  watch: {
    step0Validator: function () {
      try {
        this.$refs.blockPriceStep1Observer.validate()
          .then((valid) => {
            if (valid) {
              if (this.blockPrice.category && this.blockPrice.activity && this.blockPrice.activityType) {
                if (this.blockPrice.activityType === 'lesson') {
                  this.checkLessonSubscribers();
                } else {
                  this.$emit('on:step-1-from:validated');
                }
              }
            }
          })
        ;
      } catch (e) {
      }
    }
  },
  methods: {
    cols(section) {
      switch (this.blockPrice.activityType) {
        case LESSON_TYPE:
          return 4;
        case FORMULA_TYPE:
        case LEISURE_TYPE:
          return section === 'participants_section' ? 12 : 9;
        default:
          return 6;
      }
    },
    checkLessonSubscribers() {
      if (this.blockPrice.minActivityLevel && null === this.blockPrice.maxActivityLevel) {
        return;
      }
      if (this.blockPrice.registrationAvailableFor === 'subscribers') {
        if (this.blockPrice.allowedSubscriptionPlans.length > 0) {
          this.$emit('on:step-1-from:validated');
        } else {
          this.$flash(null, this.$t('components.form.playground.block-price.step-0.required-subscription-selection'));
        }
      } else {
        this.$emit('on:step-1-from:validated');
      }
    },
    uploadPhoto() {
      if (this.blockPrice.id === null) {
        this.$upload(this.uploaded, {entry: 'blockPrice', url: null, target: ''});
      } else {
        this.$upload(this.uploaded, {
          entry: 'blockPrice',
          url: fromIdToIriReference(URI_BLOCK_PRICE, this.blockPrice.id),
          target: 'postBlockPricePhoto'
        });
      }
    },
    uploaded(file) {
      if (this.blockPrice.id === null) {
        this.fileToken = URL.createObjectURL(file);
        this.blockPrice.mainPhoto = file;
      } else {
        this.fileToken = this.$filePath(file.contentUrl);
        this.blockPrice.mainPhoto = file['@id'];
      }
    },
    resetPhoto() {
      this.fileToken = null;

      if (this.blockPrice.id && this.blockPrice.mainPhoto) {
        _delete(this.blockPrice.mainPhoto);
      }

      this.blockPrice.mainPhoto = null;
    },
    onOptionCheck(option) {
      const findOption = this.blockPrice.playgroundOptions.find(el => el === option['@id']);

      if ("undefined" !== typeof findOption) {
        this.blockPrice.playgroundOptions.splice(this.blockPrice.playgroundOptions.indexOf(findOption), 1);
      } else {
        this.blockPrice.playgroundOptions.push(option['@id']);
      }
    },
    loadCategories() {
      if (null !== this.blockPrice.activityType) {
        getBlockPriceCategories(this.blockPrice.activityType)
          .then((response) => {
            this.categories = response.data['hydra:member'];
          })
        ;
      }
    },
    loadOptions() {
      this.options = [];
      this.isLoadingOptions = true;

      getClubOptions()
        .then((response) => {
          for (const option of response.data['hydra:member']) {
            const findInBlockPrice = this.blockPrice.playgroundOptions.find((el) => el === option['@id']);
            option.checked = "undefined" !== typeof findInBlockPrice;
            this.options.push(option);
          }
        })
        .finally(() => {
          this.isLoadingOptions = false;
        })
      ;
    },
    isActivityType(type) {
      for (const activity of this.playgroundActivities) {
        const findActivity = activity.types.find(el => el === type);
        if ("undefined" !== typeof findActivity) {
          return true;
        }
      }
      return false;
    },
    onActivityTypeUpdate() {
      this.blockPrice.pricePerParticipant = null;
      this.blockPrice.label = null;
      this.blockPrice.category = null;

      for (const activity of this.playgroundActivities) {
        const findActivityType = activity.types.find(el => el === this.blockPrice.activityType);

        if ("undefined" !== typeof findActivityType) {
          this.blockPrice.activity = null;
        }
      }

      this.loadCategories();
    },
    loadBlockPricePhoto() {
      if (this.blockPrice.id) {
        if (this.blockPrice.mainPhoto) {
          _get(this.blockPrice.mainPhoto)
            .then((response) => {
              this.fileToken = this.$filePath(response.data.contentUrl);
            })
          ;
        }
      } else {
        if (this.blockPrice.mainPhoto) {
          this.fileToken = URL.createObjectURL(this.blockPrice.mainPhoto);
        }
      }
    }
  },
  created() {
    this.loadOptions();
    this.loadCategories();
  },
  mounted() {
    this.loadBlockPricePhoto();
  },
  beforeCreate() {
    this.$vs.theme({
      primary: '#0b2772'
    });
  }
}
</script>

<style lang="scss" scoped>
@import "@lazy/form/_block-price.scss";

/deep/ .vs-checkbox--input:checked + .vs-checkbox .vs-icon {
  font-family: 'Material Icons';
}

.product-box .product-img .product-hover {
  height: 135px;
  width: 135px;
}

.note-feedback {
  font: normal normal normal 15px/25px Avenir;
  width: 100%;
  color: #A0A0A0;
  white-space: initial;
}

.img-border {
  border-radius: 8px;
}

/deep/ .ql-editor {
  min-height: 150px;
  max-height: 200px;
}
</style>
