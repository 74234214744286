<template>
  <div>
    <validation-observer
      slim
      :ref="observerRef"
    >
      <b-row>
        <b-col>
          <label class="title-step">
            {{ $t('components.form.playground.block-price.step-2.credits.title') }}
          </label>
        </b-col>
      </b-row>
      <div v-if="!isLoading">
        <b-row v-if="credits.length === 0">
          <b-col align="center">
            <label>
              {{ $t('components.form.playground.block-price.step-2.credits.no-credits') }}
            </label>
          </b-col>
        </b-row>
        <div
          class="container-scroll"
        >
          <template v-for="(credit, key) of credits">
            <div class="mt-2" :key="'credit-' + key">
              <b-row>
                <b-col align="right" class="d-flex justify-content-end align-items-center">
                  <span @click="removeCredit(credit)" class="fa pointer fa-minus text-danger-light"></span>
                </b-col>
                <b-col cols="4">
                  <label class="title-step mt-1">
                    {{ $t('components.form.playground.block-price.step-2.credits.label') }}
                  </label>
                </b-col>
                <b-col align="left" cols="7">
                  <select
                    v-model="credit.paymentToken"
                    class="form-control background-light-blue-inputs custom-select-icon-option"
                  >
                    <option disabled :value="null">{{ $t('general.actions.choose') }}</option>
                    <template v-for="(paymentToken, key) of paymentTokens">
                      <option
                        :key="'payment-token' + key"
                        :value="paymentToken['@id']"
                        :disabled="isCreditAvailable(paymentToken)"
                      >
                        {{ paymentToken.name }}
                      </option>
                    </template>
                  </select>
                  <b-form-invalid-feedback
                    :state="credit.paymentToken !== null"
                  >
                    {{ $t('validation.required') }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col class="ml-4 d-flex justify-content-start align-items-center">
                  <label>
                    {{ $t('components.form.playground.block-price.step-2.credits.public-price') }}
                  </label>
                </b-col>
                <b-col cols="4">
                  <div class="d-flex flex-row align-content-center align-items-center">
                    <b-form-input
                      v-model="credit.publicPrice"
                      type="number"
                      min="0"
                      size="sm"
                      autocomplete="off"
                      class="background-light-blue-inputs custom-right-borders"
                      :placeholder="$t('general.actions.numbers.zero')"
                    >
                    </b-form-input>
                    <div class="coins-container">
                      <img class="form-control-coin-sm" :src="coinsIcons">
                    </div>
                  </div>
                  <b-form-invalid-feedback
                    :state="parseFloat(credit.publicPrice) >= 0"
                  >
                    {{ $t('validation.required') }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <template v-for="(variation, key) of credit.blockPriceVariations">
                <b-row :key="'blockPriceVariation-' + key" class="mt-2">
                  <b-col class="ml-4 d-flex justify-content-start align-items-center">
                    <label>
                      {{ getVariationLabel(variation) }}
                    </label>
                  </b-col>
                  <b-col cols="4">
                    <div class="d-flex flex-row align-content-center align-items-center">
                      <b-form-input
                        v-model="variation.pricePerParticipant"
                        :placeholder="$t('general.actions.numbers.zero')"
                        type="number"
                        min="0"
                        size="sm"
                        autocomplete="off"
                        class="background-light-blue-inputs custom-right-borders"
                      >
                      </b-form-input>
                      <div class="coins-container">
                        <img
                          :src="coinsIcons"
                          class="form-control-coin-sm"
                        />
                      </div>
                    </div>
                    <b-form-invalid-feedback
                      :state="parseFloat(variation.pricePerParticipant) >= 0"
                    >
                      {{ $t('validation.required') }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
              </template>
            </div>
            <div class="border-bottom-grey mt-3 mb-3"/>
          </template>
        </div>
        <AddRate
          class="ml-4"
          text-link="components.form.playground.block-price.step-2.credits.add-credit-price"
          :pointer="credits.length < paymentTokens.length ? 'pointer' : ''"
          :class-icon="credits.length < paymentTokens.length ? 'fa fa-plus text-blue-darken' : 'fa fa-plus text-blue-disabled'"
          @on:add-link-click="addCredit"
        />
      </div>
      <d-custom-loader
        v-else
        class="d-flex justify-content-center"
      />
    </validation-observer>
  </div>
</template>
<script>
import {getClubPaymentTokens} from "@api/doinsport/services/payment-token/payment-token.api";
import PaymentTokenPrice from "@/classes/doinsport/PaymentTokenPrice";
import {
  getPricePaymentTokens,
  getPricePaymentTokensItems
} from "@api/doinsport/services/timetable/blocks/price/price.api";
import {
  ALL_SUBSCRIBERS_REGISTRATION,
  EVERYONE_REGISTRATION,
  LESSON_TYPE,
  SUBSCRIBERS_REGISTRATION
} from "@/classes/doinsport/Price";

const BLOCK_PRICE_OBSERVER = "blockPriceStep3Observer"

export default {
  data: () => ({
    paymentTokens: [],
    credits: [],
    isLoading: false,
    validation: require('@validation/entities/doinsport/Price'),
    coinsIcons: require('@/assets/icons/playground/new/step-1/coins-solid.svg')
  }),
  props: {
    step2Validator: {
      type: Boolean,
      default: false
    },
    blockPrice: {
      type: Object,
      default: () => {
      }
    },
    subscriptionPlans: {
      type: Array,
      default: () => [],
    },
    deletedBlockPriceVariations: {
      type: Array,
      default: () => [],
    },
    deletedBlockPricePaymentTokens: {
      type: Array,
      default: () => [],
    },
    deletedSinglePaymentTokenPrices: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    AddRate: () => import('@custom/AddLink')
  },
  computed: {
    isAvailablePublicPrice() {
      if (this.blockPrice.activityType === LESSON_TYPE) {
        if (this.blockPrice.registrationAvailableFor === EVERYONE_REGISTRATION) {
          return true;
        }
        if (this.blockPrice.registrationAvailableFor === SUBSCRIBERS_REGISTRATION || this.blockPrice.registrationAvailableFor === ALL_SUBSCRIBERS_REGISTRATION) {
          return false;
        }
      }

      return true;
    },
    observerRef() {
      return BLOCK_PRICE_OBSERVER;
    },
    blockPriceVariations() {
      return this.$store.getters["blockPrice/getBlockPriceVariations"];
    },
  },
  watch: {
    step2Validator: function () {
      if (this.credits.length === 0) {
        this.$emit('on:step-3-from:validated', []);
      } else {
        let validator = true;
        for (const credit of this.credits) {
          if (!(parseFloat(credit.publicPrice) >= 0)) {
            validator = false;
            break;
          }
          if (credit.paymentToken === null) {
            validator = false;
            break;
          }
          for (const creditVariation of credit.blockPriceVariations) {
            if (!(parseFloat(creditVariation.pricePerParticipant) >= 0)) {
              validator = false;
              break;
            }
          }
        }
        if (validator) {
          this.$emit('on:step-3-from:validated', this.credits);
        }
      }
    }
  },
  methods: {
    addCredit() {
      if (this.credits.length < this.paymentTokens.length) {
        const paymentTokenPrice = new PaymentTokenPrice(this.blockPriceVariations, {serialize_variations: true});
        paymentTokenPrice.recent = true;

        this.credits.push(paymentTokenPrice);
      }
    },
    removeCredit(paymentToken) {
      const findInCredits = this.credits.find((credit) => credit.id === paymentToken.id);
      if ("undefined" !== typeof findInCredits) {
        this.deletedBlockPricePaymentTokens.push(findInCredits);
        this.credits.splice(this.credits.indexOf(findInCredits), 1);
      }
    },
    isCreditAvailable(paymentToken) {
      const findInCredits = this.credits.find((credit) => credit.paymentToken === paymentToken['@id']);

      return "undefined" !== typeof findInCredits;
    },
    loadClubPaymentTokens() {
      getClubPaymentTokens()
        .then((response) => {
          this.paymentTokens = response.data['hydra:member'];
        })
        .finally(() => {
          this.isLoading = false;
        })
      ;
    },
    getVariationLabel(variation) {
      const findSubscriptionPlanByIri = this.subscriptionPlans.find(el => el['@id'] === variation.subscriptionPlan);
      return findSubscriptionPlanByIri.name;
    },
    initBlockPricePaymentTokens() {
      this.isLoading = true;
      this.credits = [];

      if (this.blockPrice.id && this.blockPrice.paymentTokenPrices.length > 0) {
        getPricePaymentTokens(this.blockPrice.id)
          .then((response) => {
            const blockPriceVariationsPaymentTokenPrices = [];

            for (const blockPricePaymentTokenPrice of response.data['hydra:member']) {
              if (blockPricePaymentTokenPrice.blockPrice) {
                const paymentTokenPrice = new PaymentTokenPrice(this.blockPriceVariations, {serialize_variations: true});
                paymentTokenPrice.id = blockPricePaymentTokenPrice.id;
                paymentTokenPrice.recent = false;
                paymentTokenPrice.paymentToken = blockPricePaymentTokenPrice.paymentToken;
                paymentTokenPrice.publicPrice = parseFloat(blockPricePaymentTokenPrice.pricePerParticipant);
                this.credits.push(paymentTokenPrice);
              }

              if (blockPricePaymentTokenPrice.blockPriceVariation) {
                const checkIfNotDeleted = this.deletedBlockPriceVariations.find(el => el.subscriptionPlan === blockPricePaymentTokenPrice.blockPriceVariation.subscriptionPlan);
                if ("undefined" === typeof checkIfNotDeleted) {
                  blockPriceVariationsPaymentTokenPrices.push({
                    id: blockPricePaymentTokenPrice.id,
                    recent: false,
                    subscriptionPlan: blockPricePaymentTokenPrice.blockPriceVariation.subscriptionPlan,
                    pricePerParticipant: parseFloat(blockPricePaymentTokenPrice.pricePerParticipant),
                    paymentToken: blockPricePaymentTokenPrice.paymentToken
                  });
                }
              }
            }

            if (blockPriceVariationsPaymentTokenPrices.length) {
              for (const blockPriceVariationPaymentTokenPrice of blockPriceVariationsPaymentTokenPrices) {
                const findCredit = this.credits.find(el => el.paymentToken === blockPriceVariationPaymentTokenPrice.paymentToken);
                if ("undefined" !== typeof findCredit) {

                  const findVariation = findCredit.blockPriceVariations.find(el => el.subscriptionPlan === blockPriceVariationPaymentTokenPrice.subscriptionPlan);

                  if ("undefined" !== typeof findVariation) {
                    findVariation.id = blockPriceVariationPaymentTokenPrice.id;
                    findVariation.recent = false;
                    findVariation.pricePerParticipant = blockPriceVariationPaymentTokenPrice.pricePerParticipant;
                  }
                }
              }
            }
          })
        ;
      }
    }
  },
  created() {
    this.initBlockPricePaymentTokens();
    this.loadClubPaymentTokens();
  }
}
</script>

<style lang="scss" scoped>
@import "@lazy/form/_block-price.scss";

.custom-left-borders {
  border-bottom-left-radius: unset;
  border-top-left-radius: unset;
}

.custom-right-borders {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.custom-select-icon-option {
  height: max-content;
  padding: 0.32rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.coins-container {
  border: 1px solid #ced4da;
  border-left: unset;
  background-color: #EBEBEB;
  border-bottom-right-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  border-top-left-radius: unset;
  font-size: 0.875rem;
}

.form-control-coin-sm {
  height: calc(1.5em + 0.5rem);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 0;
  border-radius: 0.2rem;
}

/deep/ .input-group-text {
  line-height: 0;
  color: #0B2772;
}

.container-scroll {
  position: relative;
  max-height: 520px;
  overflow-x: scroll;
}
</style>
