import { hydrate, randomString } from "@/utils/form";

export default class PriceVariation {
    id = randomString();
    pricePerParticipant = 0;
    blockPrice = null;
    subscriptionPlan = null;
    paymentTokenPrices = [];

    constructor(object, options) {
        if ("undefined" !== typeof options) {
            if (options.deserialize) {
                this.deserialize(object);
            }
            if (options.serialize) {
                this.serialize(object);
            }
        }
    }

    deserialize(object) {
        hydrate(this, object);
    }

    serialize(object) {
        hydrate(this, object);
    }
}
