import {_delete, _get, _post, _put, clubId} from "@api/doinsport/services/httpService";

export const OPTION_URI = "/clubs/playgrounds/options"

export const getClubOptions = () => _get(OPTION_URI +'?club.id=' + clubId + '&itemsPerPage=500');

export const postOption = (option) => _post(OPTION_URI, option);

export const putOption = (option) => _put(OPTION_URI + '/' + option.id, option);

export const getOption = (id) => _get(OPTION_URI + '/' + id);

export const deleteOption = (id) => _delete(OPTION_URI + '/' + id);
