<template>
  <b-modal
      :id="modalId"
      hide-header
      hide-footer
      hide-header-close
      size="lg"
      header-class="my-second-class"
      :body-bg-variant="'gray-lighten'"
  >
    <validation-observer
        slim
        :ref="'categoryObserver'"
    >
      <b-row>
        <b-col align="left" class="modal-title-class">
          {{ $t('views.playground.steps.step-1.category.label') }}
        </b-col>
      </b-row>
      <div class="border-bottom-grey-dark mt-3 mb-3"/>
      <b-row>
        <b-col cols="6">
          <label>
            {{ $t('views.playground.steps.step-1.category.name') }}
          </label>
        </b-col>
        <b-col cols="6">
          <label>
            {{ $t('views.playground.steps.step-1.category.details') }}
          </label>
        </b-col>
        <b-col cols="6">
          <d-text-field
              v-model="name"
              :rules="validation.name"
              place-holder="components.form.playground.block-price.step-0.general-information.name.placeholder"
              class-name="background-light-blue-inputs"
              size="sm"
              error-text="validation.text-input-three-characters"
          />
        </b-col>
        <b-col cols="6">
          <d-text-field
              v-model="description"
              :rules="validation.name"
              place-holder="components.form.playground.block-price.step-0.general-information.label.placeholder"
              class-name="background-light-blue-inputs"
              size="sm"
              error-text="validation.text-input-three-characters"
          />
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col align="center">
          <d-button
              :text="'general.actions.save'"
              :class="'d-btn-sm font-text-title d-btn btn d-btn-danger font-text-title'"
              @on:button-click="addCategory"
          />
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>
<script>
import {postClubCategory} from "@api/doinsport/services/participant/participant.api";

const MODAL_ID = 'CATEGORY_MODAL_ID';

export default {
  data: () => ({
    items: [],
    name: null,
    description: null,
    validation: require('@validation/entities/doinsport/Price.json')
  }),
  props: {
    display: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modalId() {
      return MODAL_ID;
    }
  },
  watch: {
    display: function () {
      this.$bvModal.show(this.modalId);
    }
  },
  methods: {
    addCategory() {
      try {
        this.$refs.categoryObserver.validate().then((valid) => {
          if (valid) {
            if (this.name.length > 2 && this.description.length > 2) {
              const category = {
                "club": "/clubs/" + this.$store.getters["auth/currentClub"].id,
                "name": this.name,
                "label": this.name,
                "description": this.description
              }

              postClubCategory(category).then((response) => {
                this.$bvModal.hide(this.modalId);
                this.$emit('on:category-added')
              })
            }
          }
        });
      } catch (e) {
      }
    }
  }
}
</script>
<style scoped>
.modal-title-class {
  text-align: center;
  font: normal normal 500 25px Avenir;
  letter-spacing: 0px;
  color: #3C3D43;
  opacity: 1;
}

/deep/ .modal {
  padding-top: 100px
}

/deep/ .modal-content {
  border-radius: 8px;
}

/deep/ .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 1rem;
  border-radius: 8px;
}
@media (min-width: 992px) {
  /deep/  .modal-lg, .modal-xl {
    max-width: 700px;
  }
}
</style>
